<template>
  <div class="template proposal grey lighten-3" :class="{'modal' : !$isUndefined(selectedProp)}">
    <v-app-bar :app="$isUndefined(selectedProp)" color="black" class="component" dark :fixed="$isUndefined(selectedProp)" >
      <template v-if="$isEmpty(proposal)">
        <v-toolbar-title class="white--text">
          <v-icon class="mr-2">mdi-chevron-left</v-icon>
          <span>Loading...</span>
        </v-toolbar-title>
      </template>
      <template v-else>
        <v-toolbar-title class="white--text text--truncate text-shadow" @click="goBack">
          <v-icon class="mr-2">mdi-chevron-left</v-icon>
          <span>{{proposal.client.firstName}} + {{proposal.advisor.firstName}}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="secondary" :icon="$vuetify.breakpoint.smAndDown" :to="`/@${proposal[whoIsOther].userName}`">
          <span v-if="$vuetify.breakpoint.mdAndUp" class="mr-2">Profile</span>
          <v-icon>mdi-card-account-details</v-icon>
        </v-btn>
      </template>
  </v-app-bar>


    <div class="page" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
      <v-container fill-height fluid class="align-start align-items-start d-block col-12 col-md-6">
        <template v-if="!$isEmpty(proposal)">

          <div class=" details  text--secondary">
            <template v-if="whoIsUser === 'client'">
              <bubble-right :proposal="proposal" :photoURL_thumb="proposal.client.photoURL_thumb"></bubble-right>
            </template>
            <template v-else>
              <bubble-left :proposal="proposal" :photoURL_thumb="proposal.client.photoURL_thumb" :userName="proposal[whoIsOther].userName"></bubble-left>
            </template>
          </div>

          <div class="dates">
            <div class="date" v-for="(date, index) in proposal.dates" :key="`propDate-${index}`">
              <template v-if="whoIsUser === date.who">
                <bubble-right :whoIsUser="whoIsUser" :proposal="proposal" :index="index" :date="date" :photoURL_thumb="proposal[date.who].photoURL_thumb" :timeEdited="timeEdited" @accept-meeting="acceptMeeting"></bubble-right>
              </template>

              <template v-else>
                <bubble-left :whoIsUser="whoIsUser" :proposal="proposal" :index="index" :date="date" :photoURL_thumb="proposal[date.who].photoURL_thumb" :timeEdited="timeEdited" @accept-meeting="acceptMeeting" :userName="proposal[whoIsOther].userName"></bubble-left>
              </template>

            </div>
          </div>

          <div class="new-times" v-if="whoIsUser === proposal.turn">
            <!-- <hr class="mb-3">
            <v-btn block color="secondary" @click="showTimes = true">Propose New Time</v-btn> -->
            <choose-time :loadedUser="proposal[whoIsOther]" :localUser="proposal[whoIsUser]"  @dates="selectDates" ></choose-time>
          </div>

          <v-row class="their-turn" v-else align="center" no-gutters>
            <v-col cols="auto" class="pr-8">
              <v-avatar size="30"><v-img :src="proposal[proposal.turn].photoURL_thumb"></v-img></v-avatar>
            </v-col>
            <!-- <v-col cols="auto" class="mr-6">
              <strong>Waiting on their response.</strong>
            </v-col> -->
            <v-col>
              <div class="dot-flashing"></div>
            </v-col>
          </v-row>

        </template>


        <template v-else>
          <v-skeleton-loader
            class="d-block col-12 col-md-6 mx-md-auto"
          type="list-item-avatar, divider, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
        ></v-skeleton-loader>
        </template>

      </v-container>
  </div>


<div class="actions white px-4" v-if="!$isEmpty(proposal)">
  <div class="col-12 col-md-8 mx-auto d-flex align-center pa-0 pt-1">

  <template v-if="!meetingLoading">

    <v-btn color="black" outlined dark class="flex-grow-1 ma-0 mx-0 px-2 large" @click="dialogDecline = true">
      <span>Decline</span>
    </v-btn>

    <template v-if="proposal.turn === whoIsUser ">
      <v-btn v-if="timeEdited"
      dark class="flex-grow-1 ma-0 ml-2 px-2 large bg-loop"
      @click="sendNewTimes"
      >
        <span class="text-shadow"><v-icon class="mr-2">mdi-clock-fast</v-icon>Send New Times</span>
      </v-btn>
    <v-menu offset-y v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark class="flex-grow-1 ma-0 ml-2 px-2 large bg-loop"
          v-bind="attrs"
          v-on="on"
          >
          <span class="text-shadow"><v-icon class="mr-2">mdi-calendar-check</v-icon>Accept Meeting</span>
        </v-btn>
      </template>
      <v-list>
        <v-subheader>CHOOSE A TIME</v-subheader>
        <v-list-item
        v-for="(date, index) in filteredDates"
        :key="`propdate-${index}`"
        @click="acceptMeeting(date)"
        >
        <v-list-item-title>
          <v-icon color="primary" size="18" class="mr-2">mdi-calendar</v-icon>
          <strong>{{$date.utc(date.date).local().format("MMM D")}}</strong>
          {{$date.utc(date.date).local().format("h:mm a")}} - {{$date.utc(date.date).add(30, 'minute').local().format("h:mm a")}}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    </v-menu>
  </template>
  </template>
  <template v-else>
    <strong class="mr-2">Sending</strong> <v-progress-linear indeterminate color="primary"></v-progress-linear>
  </template>
  </div>
</div>

<div ref="propbottom">

</div>

<v-dialog
  v-model="dialogDecline"
  persistent
  max-width="320"
>
  <v-card>
    <v-card-title class="headline">
      Decline this meeting?
    </v-card-title>
    <v-card-text>Care to share a reason?</v-card-text>
    <v-textarea
    class="mx-2"
    solo flat background-color="grey lighten-3"
    auto-grow
    placeholder="It's best to be polite :)"
    rows="1"
    row-height="20"
    v-model.trim="decline.reason"
    ></v-textarea>
    <v-card-actions v-if="!decline.loading">
      <v-btn
        color="green darken-1"
        text
        @click="dialogDecline = false"
      >
        Keep Meeting
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="red darken-1"
        text
        @click="declineMeeting"
      >
        Decline
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </v-card-actions>
  </v-card>
</v-dialog>




  </div>
</template>

<script>
import {db} from '@/firebase';
const bubbleLeft = () => import("@/components/shared/bubble-left.vue");
const bubbleRight = () => import("@/components/shared/bubble-right.vue");
const chooseTime = () => import("@/components/shared/choose-time.vue");
export default {
  name: 'proposal',
  components:{bubbleLeft, bubbleRight,chooseTime},
  props:['selectedProp'],
  computed: {
    userId: function (){ return this.$store.state.userId},
    user: function (){ return this.$store.state.user},
    proposals: function (){ return this.$store.state.proposals},
    // proposal: function(){
    //   let selected = this.proposals.find(item => item.id === this.proposalId)
    //   return selected;
    // },
    // proposal: function(){return this.$store.state.proposal},
    whoIsUser: function(){
      return this.proposal.client.userId === this.userId ? 'client' : 'advisor'
    },
    whoIsOther: function(){
      return this.proposal.client.userId === this.userId ? 'advisor' : 'client'
    },
    filteredDates: function(){
      if(!this.$isUndefined(this.proposal)){
        return this.proposal.dates.filter(item => item.rejected === false)
      }
      else{
        return []
      }
    }
  },
  watch:{
    proposal: function(val){

    },
    proposalId:{
      handler(val){
        process.env.NODE_ENV === "development" ? console.log( 'watch proposal', val) : null;
        this.$bind('proposal', db.collection('proposals').doc(val))
      }
    }
  },
  data: () => ({
    proposal: [],
    dialogDecline: false,
    proposalId: null,
    messages:[],
    showTimes:false,
    timeEdited: false,
    decline:{
      reason:null,
      loading:false
    },
    meetingLoading: false,
    newDates: []
  }),
  methods:{
    // getProposal(){
    //   this.$store.dispatch('getProposal',this.proposalId);
    // },
    goBack(){
      if(this.$vuetify.breakpoint.mdAndUp){
        this.$emit('close-prop', true)
      }
      else{
        this.$router.push('/my/proposals')
      }
    },
    scrollToEnd() {
        // scroll to the start of the last message
        // this.$el.scrollTop = this.$refs.propbottom.offsetTop;
        window.scrollTo(0,document.body.scrollHeight);
    },
    selectDates(data){
      process.env.NODE_ENV === "development" ? console.log( 'dates from time pickers', data ) : null;
      if(!this.$isUndefined(data) && ! this.$isEmpty(data)){
        this.timeEdited = true;
        let vThis = this;
        let utcDates = data.map( item =>{
          return {date: vThis.$date.utc(item.date).format(), rejected: false, who: this.whoIsUser}
        })
        this.newDates = utcDates;
        this.$nextTick(() => this.scrollToEnd());
      }
      else{
        this.newDates = [];
        this.timeEdited = false;
      }

    },
    async rejectOtherTimes(array){
      let arr = [...array]
      const newArr = await Promise.all(arr.map(async (i) => {
        i.rejected = true;
        return i;
      }));
      process.env.NODE_ENV === "development" ? console.log( 'array', newArr ) : null;
      let combined = [...newArr, ...this.newDates];
      return combined
    },
    sendNewTimes(){
      let vThis = this;
      this.rejectOtherTimes(this.proposal.dates).then(dateArray => {
        // process.env.NODE_ENV === "development" ? console.log( 'combined', combined, propDates, this.newDates ) : null;
        let proposal = {
          sendASAP: false,
          dates: dateArray,
          //not sure how to do this, but I'll have to email the opposite person
          turn: vThis.proposal.client.userId === vThis.userId ? 'advisor' : 'client'
        }
        process.env.NODE_ENV === "development" ? console.log( 'proposal', proposal ) : null;
        vThis.$store.dispatch('acceptProposal', {proposalId:vThis.proposal.id, proposal:  proposal}).then( result =>{
          vThis.meetingLoading = false;
        });
      })
    },
    acceptMeeting(selected){
      let vThis = this;
      let data = {
        accepted: true,
        active: false,
        accepted_Date: selected.date,
        //not sure how to do this, but I'll have to email the opposite person
        turn: this.proposal.client.userId === this.userId ? 'advisor' : 'client'
      }
      this.$store.dispatch('acceptProposal', {proposalId: this.proposal.id, proposal: data}).then((results) =>{
        process.env.NODE_ENV === "development" ? console.log( 'results' ) : null;
        vThis.$router.push('/my/calendar')
      })


    },
    declineMeeting(){
      let vThis = this;
      //show loader
      vThis.decline.loading = true;
      let data = {
        proposalId: vThis.proposalId,
        reason: vThis.decline.reason,
        active: false,
        rejected:true,
        rejected_reason:data.reason,
        turn: this.proposal.client.userId === this.userId ? 'advisor' : 'client'
      }
      //call db
      vThis.$store.dispatch('rejectProposal', data).then(result =>{
        vThis.decline.loading = false;
        vThis.dialogDecline = false;
        vThis.$router.push('/my/proposals')
      });
    }
  },
  created(){
    this.proposalId = !this.$isUndefined(this.selectedProp) ? this.selectedProp : this.$route.params.proposalId;
    // this.getProposal();
    this.scrollToEnd()
  },
  updated() {
      // whenever data changes and the component re-renders, this is called.
      this.$nextTick(() => this.scrollToEnd());
  },
  beforeDestroy(){
    // this.$unbind('proposal').catch(e=>{process.env.NODE_ENV === "development" ? console.log( 'error', e ) : null;})
  }
};
</script>


<style lang="scss">
.proposal.template{
  position: relative;
  .page{
      padding-top: 64px;
      padding-bottom: 74px;
  }
  .subtitle-1{
    line-height: 18px;
  }
  hr{
    border-style: dashed;
    opacity:.3;
  }
  .actions{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
  }
}
.mobile .proposal.page{

}

.modal.proposal.template{
  position: relative;
  .page{
    padding-top: 0;
  }
  .container{
    width: 100%!important;
    max-width: 100%;
  }
  .actions{
    // position: relativ
    width: 100%;
    max-width: 500px;
  }
}
.mobile .proposal.component{
  .v-toolbar__content{
    padding:4px 2px;
  }
}
</style>
